import { Fragment, PropsWithChildren } from "react";

export interface IfProps extends PropsWithChildren{
    c: boolean;
}
export default function If(p: IfProps){
    const child = p.c ? p.children : null;
    return child ? (
        <Fragment>
            {child}
        </Fragment>
    ): null;
}