import { Stack, Typography } from "@mui/material";
import parseBRL from "../../utils/parse_brl";

export interface InfoItemProps{
    label: string;
    value: string;
}

export default function InfoItem(props: InfoItemProps){
    return (
        <Stack justifyContent="center" alignItems="center">
            <Typography color="primary" variant="caption">
                {props.label}
            </Typography>
            <Typography>
                {props.value}
            </Typography>
        </Stack>
    )
}