import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import projectId from "../../utils/project_id";
import { useAppSelector } from "../root_store/hooks";

export interface ProjectOpenConfig{
    /**domains define what functionalities a project has. */
    domains: { [domain: string]: boolean };
    /**flags modify functionality behavior. */
    flags: { [flag: string]: any };
    /**ui sets the configuration for how the UI looks like. */
    ui: {
        primary_color: string;
        mode: 'light' | 'dark';
        public_name: string;
        logo_url: string;
    }
}

export interface ProjectStoreState{
    loaded: boolean;
    id?: string;
    project?: ProjectOpenConfig;
    error?: Error;
}

export const projectSlice = createSlice({
    name: 'project_store',
    initialState: {
        id: undefined,
        loaded: false,
        project: undefined,
    } as ProjectStoreState,
    reducers: {
        setProject: (state, action: PayloadAction<{projectConfig?: ProjectOpenConfig, error?: Error}>) => {
            state.loaded = true;
            state.project = action.payload.projectConfig;
            state.error = action.payload.error;
            state.id = projectId;
            return state;
        }
    }
})

export const useProject = () => useAppSelector(({project}) => project);