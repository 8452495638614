import { Stack, Typography, Paper, Divider } from "@mui/material";
import { EasyFormInput } from "../../../shared/components/easy_form";
import FullFlexEasyForm from "../../../shared/components/full_flex_easy_form";
import SellBottomNavigation, { SellBottomButtonNextForm, SellBottomButtonPrevirous } from "../sell_bottom_navigation";
import SellDetailsController from "./controller";

export default function SellDetails(props: {name: string}){

    const controller = SellDetailsController();
    
    return (
        <Stack flex={1}>
            <FullFlexEasyForm fields={controller.fields} onSubmit={controller.submit}>
                <Stack flex={1} marginTop={1}>
                    <Stack spacing={1} padding={1}>
                        <Paper>
                            <Stack spacing={1} padding={1}>
                                <Typography align='center'>
                                    DETALHES DA VENDA
                                </Typography>
                                <EasyFormInput field="clientName"/>
                                <EasyFormInput field="clientPhone"/>
                                <EasyFormInput field="warranty"/>
                                <EasyFormInput field="description"/>
                            </Stack>
                        </Paper>
                    </Stack>
                </Stack>
                <SellBottomNavigation>
                    <SellBottomButtonPrevirous/>
                    <SellBottomButtonNextForm name={props.name}/>
                </SellBottomNavigation>
            </FullFlexEasyForm>
        </Stack>
    )
}