import { EasyForm, EasyFormInput, EasyFormAction, EasyFormWriteOnly } from "../../shared/components/easy_form";
import { Button, Chip, Divider, Stack, Typography } from "@mui/material";
import useCRUDEdit from "../../shared/crud/use_crud_edit";
import { Delete } from "@mui/icons-material";
import ScreenLoading from "../../shared/components/screen_loading";
import ScreenPaper from "../../shared/components/screen_paper";
import { ProductFields } from "./model";
import ProductService from "./service";
import ControlInfo from "../../shared/components/control_info";
import styled from "@emotion/styled";
import dialogHelper from "../../shared/components/easy_dialog";
import { Fragment, useRef, useState } from "react";
import StockService from "../stock/service";
import ProductGallery from "./product_gallery";
import CRUDEditContext from "../../shared/crud/crud_edit_context";

const NameInput = styled(EasyFormInput)({
    '& input': {
        textTransform: 'uppercase'
    }
})

export default function ProductEdit(props: any){
    const savePhotosRef = useRef<(...params: any[]) => Promise<void>>();
    const create = useCRUDEdit({
        props,
        fieldsGen: ProductFields,
        CRUDService: ProductService,
        onSave: async (...params) =>  await savePhotosRef?.current?.(...params),
    });
    const [stockService] = useState(() => new StockService());

    const deleteAction = () => {
        dialogHelper.showLoading();
        stockService.list()
        .then(stocks => {
            const stockList = Object.values(stocks).filter(s => s.data.product.uid === create.uid);
            dialogHelper.showDialog({
                title: "CONFIRMAR EXCLUSÃO",
                content: !stockList.length ? "Tem certeza de que deseja excluir esse item?" : (
                    <Fragment>
                        <Typography color="error">
                            Este produto possui os seguintes estoques cadastrados no sistema, se você excluí-lo, todos os estoques serão EXCLUIDOS também, deseja continuar?
                        </Typography>
                        <ul>
                            {stockList.map(stock => (
                                <Typography component="li" key={stock.uid}>
                                    {stock.data.store.label} - {stock.data.amount} itens
                                </Typography>
                            ))}
                        </ul>
                    </Fragment>
                ),
                locked: true,
                buttons: [
                    dialogHelper.okButton({content: 'NÃO', buttonProps: {color: 'inherit', variant: 'text'}}),
                    dialogHelper.okButton({content: 'SIM', callback: create.deleteConfirmed, buttonProps: {color: 'error'}}),
                ]
            });
        })
        .catch(dialogHelper.showError);
        
    }

    if(create.initializing){
        return <ScreenLoading/>
    }

    return (
        <CRUDEditContext.Provider value={create}>
            <ScreenPaper>
                <EasyForm {...create.easyFormProps}>
                    <Stack spacing={1}>
                        <Typography>
                            {!create.uid && 'CRIAR'} PRODUTO
                        </Typography>
                        {create.uid ? <ControlInfo {...create}/> : undefined}
                        <NameInput field="name"/>
                        <EasyFormInput field="description"/>
                        <EasyFormInput field="cost_value"/>
                        <EasyFormInput field="sell_value"/>
                        <Divider><Chip label="FOTOS"/></Divider>
                        <ProductGallery savePhotosRef={savePhotosRef}/>
                        <EasyFormWriteOnly>
                            <Divider><Chip label="AÇÕES"/></Divider>
                            <EasyFormAction>
                                <Button fullWidth color="success" type="submit">
                                    SALVAR
                                </Button>
                            </EasyFormAction>
                            {create.uid ? 
                            <Button startIcon={<Delete/>} color="error" onClick={deleteAction}>
                                DELETAR
                            </Button>
                            : undefined}
                        </EasyFormWriteOnly>
                    </Stack>
                </EasyForm>
            </ScreenPaper>
        </CRUDEditContext.Provider>
    )
}