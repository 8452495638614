import { DatedProps } from "../../shared/models/model";
import ModelFieldsGen, { ModelFieldsGenOptions } from "../../shared/utils/model_fields_gen";
import { Photo, PhotoList } from "../file/model";


export interface ProductProps extends DatedProps{
    name: string;
    description: string;
    cost_value: number;
    sell_value: number;
    amount: number;
    photos?: PhotoList;
}

export const ProductFields = (options?: ModelFieldsGenOptions) => {
    return ModelFieldsGen({
        name: {
            field: "name",
            type: "text",
            label: "Nome",
            required: true,
        },
        description: {
            field: "description",
            type: "text",
            label: 'Descrição'
        },
        cost_value: {
            field: "cost_value",
            type: "number",
            label: "Custo Real Un. R$",
            required: true
        },
        sell_value: {
            field: "sell_value",
            type: "number",
            label: "Valor de Venda Un. R$",
            required: true,
        },
        amount: {
            field: "amount",
            type: "int",
            label: "Quantidade"
        }
    }, options)
}