import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import FixedTextField from "../../shared/components/fixed_text_field";
import NumberInput from "../../shared/components/number_input";
import useIncrementState from "../../shared/hooks/use_increment_state";
import sell from "../sell";
import { PaymentLabels } from "./model";
import CreditTimesInput from "../general_configs/credit_times_input";

export interface PaymentMethods{
    money?: number;
    debt?: number;
    pix?: number;
    credit?: number;
    creditX?: string;
}

export interface PaymentInputsChangeProps{
    valid: boolean;
    values: PaymentMethods;
}
export interface PaymentInputsProps{
    totalValue: number;
    defaults: PaymentMethods;
    onChangeInputs: (props: PaymentInputsChangeProps) => void;
}

export default function PaymentInputs(props: PaymentInputsProps){

    const [state, setState] = useIncrementState<PaymentMethods>({...props.defaults});

    const totalPayed = Object.values(state).reduce((t: number, i) => typeof i === 'number' && i ? t+i : t, 0);
    let changeValue = totalPayed - props.totalValue;
    const balance = changeValue;
    let valid = balance >= 0;
    const missingCreditX = Boolean(state.credit && !state.creditX);
    if(changeValue < 0) changeValue = 0;
    else if(changeValue > 0 && (!state.money || changeValue > state.money)){
        valid = false;
    }
    if(missingCreditX){
        valid = false;
    }

    useEffect(() => {
        props.onChangeInputs({values: state, valid});
    }, [state]);

    const change = (e: any) => setState({[e.target.name]: e.target.value});


    return (
        <Stack spacing={1} padding={1}>
            <NumberInput
                value={props.totalValue}
                variant="filled"
                label="Total a Pagar"
                readOnly
            />
            {Object.entries(PaymentLabels).map(([key, label]) => key !== 'creditX' && (
                <NumberInput
                    value={state[key]||null}
                    onChange={change}
                    name={key}
                    label={label}
                />
            ))}
            <CreditTimesInput 
                payment={state}
                error={missingCreditX}
                setPayment={setState}
                helperText={missingCreditX ? 'Defina o parcelamento do crédito.' : undefined}
            />
            <NumberInput
                value={totalPayed}
                variant="filled"
                error={balance < 0 ? true : false}
                label="Total Pago"
                readOnly
            />
            <NumberInput
                value={changeValue}
                variant="filled"
                label="Troco"
                readOnly
            />
        </Stack>
    )
}