import deepGet from "../../shared/utils/deep_get";
import ModelFieldsGen, { ModelFieldsGenOptions } from "../../shared/utils/model_fields_gen";
import ZipInput from "./zip_input";


export interface AddressProps{
    street: string;
    number: string;
    neighborhood: string;
    complement: string;
    zip: string;
    city: string;
    state: string;
    country: string;
}

export const AddressFields = (options?: ModelFieldsGenOptions) => {
    return ModelFieldsGen({
        street:{
            field: 'street',
            type: 'text',
            label: 'Logradouro',
            required: true,
        },
        number:{
            field: 'number',
            type: 'text',
            label: 'Número',
            required: true,
        },
        neighborhood:{
            field: 'neighborhood',
            type: 'text',
            label: 'Bairro',
            required: true,
        },
        complement:{
            field: 'complement',
            type: 'text',
            label: 'Complemento',
        },
        city: {
            field: 'city',
            type: 'text',
            label: 'Cidade',
            required: true,
        },
        zip:{
            field: 'zip',
            type: 'maskedtext',
            label: 'CEP',
            //mask: "00000-000",
            component: ZipInput,
            defaultValue: null,
        },
        state:{
            field: 'state',
            type: 'select',
            label: 'UF',
            defaultValue: 'MG',
            options: ufOptions,
            required: true,
        },
        country:{
            field: 'country',
            type: 'maskedtext',
            label: 'País',
            defaultValue: 'BR',
            mask: 'aa',
            required: true,
        },
    }, options);
}

export const ufList = ['AC','AL','AP','AM','BA','CE','DF','ES','GO','MA','MT','MS','MG','PA','PB','PR','PE','PI','RJ','RN','RS','RO','RR','SC','SP','SE','TO'];
const ufOptions = ufList.map(i => ({label: i, value: i}));

export const makeAddressString = (address: AddressProps) => {
    const t = (before: string, sufix: string, next: string = '') => {
        const v = deepGet(sufix, address);
        return v ? before + v + next : '';
    }
    return `${t('', 'street')}${t(', nº ', 'number')} - ${t('','neighborhood')}${t(', ','city')}${t(' - ','state')}${t(', ','zip')}`;
}