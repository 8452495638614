import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { BottomNavigation, BottomNavigationAction, Divider, Stack } from '@mui/material';
import { PropsWithChildren, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { routeConfig, SellWizardOrder } from '..';
import dialogHelper from '../../../shared/components/easy_dialog';
import EasyFormContext from '../../../shared/components/easy_form/easy_form_context';

export function SellBottomButtonPrevirous(props: {beforeRun?: () => Promise<any> | void}){
    const navigate = useNavigate();
    return (
        <BottomNavigationAction label="Anterior" icon={<ArrowBack/>} showLabel onClick={async () => {
            if(props.beforeRun) await props.beforeRun();
            navigate(-1);
        }}/>
    )
}
export function SellBottomButtonNext(props: {beforeRun?: () => Promise<any>, name: string, route?: string}){
    const navigate = useNavigate();
    return (
        <BottomNavigationAction label="Próximo" icon={<ArrowForward/>} showLabel onClick={async () => {
            const beforeRun = props.beforeRun || (async () => {});
            await beforeRun()
            .then(() => {
                let nextRoute: string;
                if(props.route){
                    nextRoute = props.route;
                }
                else{
                    const i = SellWizardOrder.findIndex(item => item.name === props.name);
                    nextRoute = SellWizardOrder[i+1].name;
                }
                navigate(`/${routeConfig.key}/${nextRoute}`);
            })
            .catch((e) =>{
                if(e.message) dialogHelper.showError(e);
            });
        }}/>
    )
}

export function SellBottomButtonNextForm(props: {name: string}){
    const context = useContext(EasyFormContext);

    const submit = context.formConfig.handleSubmit(context.easyFormProps.onSubmit, () => {throw new Error()});

    return (
        <SellBottomButtonNext name={props.name} beforeRun={submit} />
    )
}

export default function SellBottomNavigation(props: PropsWithChildren){
    return (
        <Stack>
            <Divider/>
            <BottomNavigation>
                {props.children}
            </BottomNavigation>
        </Stack>
    )
}