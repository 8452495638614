import projectId from "../../utils/project_id";
import fetcher from "../../utils/fetcher";
import { ProjectOpenConfig, projectSlice } from ".";
import { store } from "../root_store/store";
import forage from "../cache";

export const loadProject = async () => {
    const id = projectId;
    const cacheKey = `project_config_${id}`;
    let projectConfig = await forage.getItem<ProjectOpenConfig>(cacheKey);
    let error: Error | undefined;
    if(!projectConfig){
        projectConfig = await fetcher('project_config').then(r => r.json());
        await forage.setItem(cacheKey, projectConfig);
    }
    store.dispatch(projectSlice.actions.setProject({projectConfig: projectConfig || undefined, error}));
}