import { ComponentType } from "react";
import { Navigate, Route } from "react-router-dom";
import useAuth from "../hooks/use_auth";

interface CRUDRoute{
    path?: string;
    Component: ComponentType<any>;
    ComponentProps?: any;
    expectedPerm?: string[];
}

export interface MakeCRUDRoutesProps{
    perm: string;
    routes: {
        [key: string]: CRUDRoute | undefined;
        create?: CRUDRoute;
        update?: CRUDRoute;
        view?: CRUDRoute;
        list?: CRUDRoute;
    } 
}

const Component404 = () => {
    console.log('invalid route: '+ window.location.href);
    return <Navigate to="/"/>
}
const route404 = () => (
    <Route path="*" element={<Component404/>}/>
)

export default function MakeCRUDRoutes(props: MakeCRUDRoutesProps){
    const auth = useAuth();

    const perm = auth.user?.perms[props.perm]||'';
    const readOnly = perm[0] === 'r';

    return Object.entries(props.routes)
    .map(([key, crudRoute]) => {
        const defaults = DEFAULTS[key] || DEFAULTS.anyRoute;
        const expPerm = crudRoute?.expectedPerm || defaults?.expectedPerm || [];
        if(expPerm.includes(perm[0])){
            const Component = crudRoute!.Component;
            return (
                <Route 
                    path={crudRoute?.path||defaults.path} 
                    element={<Component readOnly={readOnly} {...crudRoute?.ComponentProps}/>}
                />
            )
        }
        return undefined;
    })
    .filter(item => item)
    .concat(route404());
}

const DEFAULTS: {[key: string]: Partial<CRUDRoute>} = {
    create: {
        path: '/create',
        expectedPerm: ['s', 'w'],
    },
    update: {
        path: 'edit/:id',
        expectedPerm: ['s', 'w'],
    },
    view: {
        path: 'edit/:id',
        expectedPerm: ['r'],
    },
    list: {
        path: '',
        expectedPerm: ['s', 'w', 'r'],
    },
    anyRoute: {
        expectedPerm: ['s', 'w', 'r'],
    }
}