import MinPerm from "../../../shared/utils/min_perm";
import parseBRL from "../../../shared/utils/parse_brl";
import { ProductProps } from "../../product/model";
import ProductService from "../../product/service";
import { Report } from "../model";

const ProductsReport: Report = {
    name: 'Produtos',
    checkPerm: async u => MinPerm(u.perms.report, 'r'),
    options: {},
    validateOptions: o => o,
    columns: [
        {field: 'name', headerName: 'Produto', 'type': 'multiline', minWidth: 250 },
        {field: 'cost_value', headerName: 'Custo', type: "money", minWidth: 140 },
        {field: 'sell_value', headerName: 'Venda', type: "money", minWidth: 140 },
    ],
    defaultSort: [{field: 'name', sort: 'asc'}],
    process: async (o) => {
        const products = Object.values(await new ProductService().list()).map(i => {
            //@ts-ignore
            i.data.id = i.uid;
            return i.data;
        })
        return products;
    },
    summaries: async (options: any, values: ProductProps[]) => {
        let totalCost = 0, totalSellValue = 0;
        values.forEach(p => {
            if(p.cost_value) totalCost += p.cost_value;
            if(p.sell_value) totalSellValue += p.sell_value;
        })
        return [
            [['Custo Total', parseBRL(totalCost)], ['Venda Total', parseBRL(totalSellValue)]],
        ]
    },
    print: async (options: any, values: ProductProps[]) => {
        return {
            name: 'Relatório de Produtos',
            options: {},
            summaries: await ProductsReport.summaries(options, values),
            columns: [
                ...ProductsReport.columns as any,
                {headerName: 'Obs.'},
                {headerName: 'Obs.'},
            ],
            values,
        }
    }
}

export default ProductsReport;