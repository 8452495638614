import { createContext } from "react";
import { SellProps } from "../sell/model";


export interface SellWizardContextValue{
    sell: SellProps;
}

const SellWizardContext = createContext({} as SellWizardContextValue);

export default SellWizardContext;

