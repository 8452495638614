import { useEffect, useMemo, useState } from "react";
import List from "../../shared/components/list";
import { hashDate, useSummariesController } from "./controller";
import usePromisedState from "../../shared/hooks/use_promised_state";
import SellService from "../sell/service";
import GoField, { goFieldColumn } from "../../shared/crud/use_crud_list/go_field";
import { getChange, paymentStringify } from "../payment/model";
import { calcTax } from "../general_configs/model";
import { calcSellCost } from "../sell/model";

export default function SummariesSellList(){
    const controller = useSummariesController();
    
    const service = useMemo(() => new SellService(), []);
    const getSells = () => service.list().then(s => Object.values(s));
    const [list] = usePromisedState({initialState: getSells})
    const [filtred, setFiltred] = useState<typeof list['value']>([]);

    useEffect(() => {
        if(list.resolved){
            setFiltred(list.value?.filter(i => i.data.cancelled ? controller.filterItem({date: i.data.cancelDate, storeUid: i.data.store?.uid!}) : controller.filterItem({date: i.data.crtAt, storeUid: i.data.store?.uid!})));
        }
    }, controller.listsDeps(list.resolved));
    
    return (
        <List
            rows={filtred!}
            defaultSort={[{ field: 'updAt', sort: 'desc' }]}
            getRowId={(r) => r.uid}
            columns={[
                //@ts-ignore
                {...goFieldColumn, renderCell: p => <GoField {...p} prefix="/sell" target="_blank"/>},
                {field: 'uid', headerName: 'Cupom'},
                {field: 'data.seller.label', headerName: 'Vendedor', },
                {field: 'data.store.label', headerName: 'Loja', },
                {field: 'data.cancelled', headerName: 'Cancelada', type: 'boolean'},
                {field: 'data.totalValue', headerName: 'Faturamento', type: "money" },
                {field: 'tax', headerName: 'Taxas', type: "money", valueGetter: p => calcTax(p.row.data) },
                {field: 'cost', headerName: 'Custo', type: "money", valueGetter: p => calcTax(p.row.data) + calcSellCost(p.row.data) },
                {field: 'profit', headerName: 'Lucro', type: "money", valueGetter: p => p.row.data.totalValue - (calcTax(p.row.data) + calcSellCost(p.row.data)) },
                {field: 'data.payment.money', headerName: 'Dinheiro', type: "money" },
                {field: 'data.payment.pix', headerName: 'PIX', type: "money" },
                {field: 'data.payment.debt', headerName: 'Débito', type: "money" },
                {field: 'data.payment.credit', headerName: 'Crédito', type: "money" },
                {field: 'change', headerName: 'Troco', type: "money", valueGetter: p => getChange(p.row.data.payment, p.row.data.totalValue) },
                {field: 'data.crtAt', headerName: 'Vendido em', type: 'dateTime'},
            ]}
            loading={!list.resolved}
            autoHeight
        />
    )
}