import { DatedProps, DBRef, DBRefMap } from "../../shared/models/model";
import ModelFieldsGen, { ModelFieldsGenOptions } from "../../shared/utils/model_fields_gen";
import parseBRL from "../../shared/utils/parse_brl";
import { PaymentMethods, PaymentValues } from "../payment/model";
import ProductDBRef from "../product/dbref";
import { ProductProps } from "../product/model";
import { StockProps } from "../stock/model";
import StoreDBRef from "../store/dbref";
import { StoreProps } from "../store/model";
import UserDBRef from "../user/dbref";
import { UserProps } from "../user/model";
import ZoneDBRef from "../zone/dbref";
import { ZoneProps } from "../zone/model";

type StocksRefMap = DBRefMap<StockProps, any, {amount: number}>;

export interface SellItem{
    product: DBRef<ProductProps, 'name'>;
    amount: number;
    costValue: number;
    idealSellValue: number;
    sellValue: number;
    fonts: StocksRefMap;
    gift: boolean;
}

export const SellItemFields = (options?: ModelFieldsGenOptions) => {
    return ModelFieldsGen({
        product: {
            field: 'product',
            type: 'dbref',
            required: true,
            label: 'Produto',
            component: ProductDBRef,
        },
        amount: {
            field: 'amount',
            type: 'int',
            label: 'quantidade',
            required: true
        },
        costValue: {
            field: 'costValue',
            type: 'number',
            label: 'Valor min.',
            readOnly: true,
            required: true
        },
        idealSellValue: {
            field: 'idealSellValue',
            type: 'number',
            label: 'Valor sugerido',
            readOnly: true,
            required: true
        },
        sellValue: {
            field: 'sellValue',
            type: 'number',
            label: 'Valor venda',
            readOnly: true,
            required: true
        },
        gift: {
            field: 'gift',
            type: 'checkbox',
            label: 'Brinde',
        }
    })
}

export interface SellProps extends DatedProps{
    clientName: string;
    clientPhone?: string;
    description?: string;
    warranty: string;
    totalValue: number;
    payment: PaymentMethods;
    items: {[key: string]: SellItem};
    cancelDate?: EpochTimeStamp;
    cancelled?: boolean;
    reverted?: boolean;
    seller: DBRef<UserProps, 'name'>;
    store?: DBRef<StoreProps, 'name'>;
    zone: DBRef<ZoneProps, 'name'>;
    sellWarranties?: {[stockHistoryUid: string]: boolean};
    tax?: PaymentValues;
}


export const SellFields = (options?: ModelFieldsGenOptions) => {
    return ModelFieldsGen({
        clientName: {
            field: "clientName",
            type: "text",
            label: "Nome do Cliente",
            required: true,
        },
        clientPhone: {
            field: "clientPhone",
            type: "maskedtext",
            label: "Telefone do Cliente",
            mask: '(00) 9 0000-0000',
        },
        description:{
            field: 'description',
            type: 'text',
            label: 'Observação',
        },
        warranty: {
            field: 'warranty',
            type: 'text',
            label: 'Garantia'
        },
        seller: {
            field: 'seller',
            type: 'dbref',
            label: 'Vendedor',
            component: UserDBRef,
        },
        store: {
            field: 'store',
            type: 'dbref',
            label: 'Loja',
            component: StoreDBRef,
        },
        zone: {
            field: 'zone',
            type: 'dbref',
            label: 'Zona',
            component: ZoneDBRef,
        },
        cancelled: {
            field: 'cancelled',
            type: 'checkbox',
            label: 'Cancelado'
        },
        reverted: {
            field: 'reverted',
            type: 'checkbox',
            label: 'Revertido'
        },
        totalValue: {
            field: 'totalValue',
            type: 'number',
            label: 'Valor Total',
            readOnly: true
        }
    }, options)
}

export function calcSellCost(sell: SellProps){
    let cost = 0;
    Object.values(sell.items||{}).forEach(item => {
        cost += (item.costValue||0) * (item.amount||0);
    })
    return cost;
}