import { BottomNavigation, Checkbox, IconButton, Stack, Typography } from '@mui/material';
import { Fragment } from 'react';
import List from '../../../shared/components/list';
import ScreenLoading from '../../../shared/components/screen_loading';
import ScreenPaper from '../../../shared/components/screen_paper';
import SellBottomNavigation, { SellBottomButtonNext, SellBottomButtonPrevirous } from '../sell_bottom_navigation';
import SelectItemsController from './controller';
import { Camera, CameraAlt, Photo } from '@mui/icons-material';
import dialogHelper from '../../../shared/components/easy_dialog';
import Gallery from '../../../shared/components/gallery';

export default function SelectItems(props: {name: string}){
    const controller = SelectItemsController();

    if(!controller.products.resolved) return (
        <ScreenLoading/>
    )

    const showPhotos = (id: string) => {
        const p = controller.products.value?.find(pro => pro.uid === id)!;
        const photos = Object.values(p?.data.photos||{});
        dialogHelper.showDialog({
            title: p?.data.name,
            content: photos.length ? (
                <Gallery
                    imageList={photos}
                    readOnly
                />
            ) : <Typography>Nenhuma foto cadastrada.</Typography>,
            buttons: [dialogHelper.okButton()],
        })
    }
    
    return (
        <Stack flex={1}>
            <ScreenPaper flex1>
                <Typography align='center'>
                    SELEÇÃO DE PRODUTOS
                </Typography>
                <List
                    columns={[
                        {headerName: 'Fotos', field: '', minWidth: 70, width: 70, renderCell: ({id}) => (
                            <IconButton color="primary" onClick={() => showPhotos(id as any)}>
                                <CameraAlt/>
                            </IconButton>
                        )},
                        {headerName: 'Produto', field: 'data.name', minWidth: 300},
                        {headerName: 'Valor Sugerido', field: 'data.sell_value', type: 'money'},
                        {headerName: 'Valor Mínimo', field: 'data.cost_value', type: 'money'},
                    ]}
                    checkboxSelection
                    getRowId={(i) => i.uid}
                    onSelectionModelChange={(selected) => controller.setSelectedProducts(selected)}
                    selectionModel={controller.selectedProducts}
                    disableSelectionOnClick
                    loading={!controller.products.resolved}
                    rows={controller.products.value || []}
                />
            </ScreenPaper>
            <SellBottomNavigation>
                <SellBottomButtonPrevirous/>
                <SellBottomButtonNext name={props.name} beforeRun={controller.submit}/>
            </SellBottomNavigation>
        </Stack>
    )
}