import { useState, useContext } from "react";
import dialogHelper from "../../../shared/components/easy_dialog";
import DeepAssign from "../../../shared/utils/deep_assign";
import { SellFields } from "../../sell/model";
import SellService from "../../sell/service";
import SellWizardContext from "../sell_wizard_context";

export default function SummaryController(){
    const {sell} = useContext(SellWizardContext);
    const [fields] = useState(() => SellFields({defaults: sell}));
    const [service] = useState(() => new SellService());

    const submit = () => {
        dialogHelper.showLoading();
        return service.create({data: sell})
        .then((crudSell) => {
            sell['_uid'] = crudSell.uid;
            Object.assign(sell, crudSell.data);
            dialogHelper.closeDialog();
            return crudSell;
        })
    }

    return {
        sell, fields, submit
    }
}