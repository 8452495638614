import ModelFieldsGen, { ModelFieldsGenOptions } from "../../shared/utils/model_fields_gen";
import parseBRL from "../../shared/utils/parse_brl";

export interface PaymentValues{
    money?: number;
    debt?: number;
    pix?: number;
    credit?: number;
}

export interface PaymentMethods extends PaymentValues {
    creditX?: string;
    change?: number;
    [key: string]: any;
}


export const PaymentLabels = {
    money: 'Dinheiro', 
    debt: 'Débito', 
    pix: 'PIX', 
    credit: 'Crédito',
}

export const paymentStringify = (p: PaymentMethods, totalValue: number, withoutChange?: boolean) => {
    p = p || {};
    let str = Object.keys(PaymentLabels).map(key => p[key] ? labelStringify[key](p) : undefined).filter(i => i).join('; ');
    if(str && !withoutChange){
        str += `; Troco: ${parseBRL(Object.values(p).reduce((t, i) => typeof i === 'number' ? t + i : t, 0) - totalValue)}`;
    }
    return str;
}

export const getChange = (p: PaymentMethods, totalValue: number) => {
    const totalPaid = Object.values(p||{}).reduce((t,v) => typeof v === 'number' ? t + v : t, 0);
    return totalValue - totalPaid;
}

const labelStringify = {
    money: (p:PaymentMethods) => `${PaymentLabels.money}: ${parseBRL(p.money!)}`, 
    debt: (p:PaymentMethods) => `${PaymentLabels.debt}: ${parseBRL(p.debt!)}`, 
    pix: (p:PaymentMethods) => `${PaymentLabels.pix}: ${parseBRL(p.pix!)}`, 
    credit: (p:PaymentMethods) => `${PaymentLabels.credit}: ${parseBRL(p.credit!)} (${p.creditX})`, 
}