import DateToString from "../../../shared/utils/date_to_string";
import MinPerm from "../../../shared/utils/min_perm";
import PhoneSOService from "../../phone_so/service";
import SellService from "../../sell/service";
import StoreDBRef from "../../store/dbref";
import { Report } from "../model";

export interface ClientReportValue{
    id: string;
    name: string;
    cpf: string;
    address: string;
    phone: string;
    operation: string;
    store: string;
    value: number;
    date: EpochTimeStamp;
}

const ClientReport: Report = {
    name: 'Clientes',
    checkPerm: async u => MinPerm(u.perms.report, 'w'),
    options: {
        startDate: {
            field: 'startDate',
            type: 'date',
            required: true,
            label: 'Data Início',
        },
        endDate: {
            field: 'endDate',
            type: 'date',
            required: true,
            label: 'Data Fim',
        },
        store: {
            field: 'store',
            type: 'dbref',
            component: StoreDBRef,
            label: 'Loja',
        }
    },
    validateOptions: async o => {
        if(o.startDate > o.endDate) throw new Error('A data de início não pode ser maior que a data fim.');
        o.startDate.setHours(0,0,0);
        o.endDate.setHours(23,59,0);
        return o;
    },
    columns: [
        {field: 'store', headerName: 'Loja'},
        {field: 'name', headerName: 'Nome'},
        {field: 'cpf', headerName: 'CPF'},
        {field: 'address', headerName: 'Endereço'},
        {field: 'phone', headerName: 'Telefone'},
        {field: 'operation', headerName: 'Operação'},
        {field: 'value', headerName: 'Valor Pago', type: 'money'},
        {field: 'date', headerName: 'Data', type: 'dateTime'},
    ],
    defaultSort: [{field: 'date', sort: 'desc'}],
    process: async o => {
        
        const values: ClientReportValue[] = [];

        const sells = await new SellService().list();
        for(let s of Object.values(sells)){
            if(
                !s.data.cancelled && 
                s.data.crtAt >= o.startDate && 
                s.data.crtAt <= o.endDate && 
                (!o.store || o.store.uid === s.data.store?.uid! )
            ){
                values.push({
                    id: s.uid!,
                    operation: s.uid!,
                    store: s.data.store?.label!,
                    name: s.data.clientName,
                    phone: s.data.clientPhone||'',
                    cpf: '',
                    address: '',
                    date: s.data.crtAt,
                    value: s.data.totalValue,
                })
            }
        }

        const sos = await new PhoneSOService().list();
        for(let s of Object.values(sos)){
            const sDate: any = s.data.dateOutcome || s.data.dateIncome || s.data.crtAt;
            if(
                !s.data.cancelled &&
                s.data.payment &&
                sDate >= o.startDate && 
                sDate <= o.endDate && 
                (!o.store || o.store.uid === s.data.store?.uid! )
            ){
                values.push({
                    id: s.uid!,
                    operation: s.uid!,
                    store: s.data.store?.label!,
                    name: s.data.clientName,
                    phone: s.data.clientPhone||'',
                    cpf: s.data.clientId,
                    address: s.data.clientAddress,
                    date: sDate,
                    value: s.data.totalValue || 0,
                })
            }
        }
        return values;
    },
    summaries: async (options: any, values: ClientReportValue[]) => {
        return [
            [['Loja', options.store?.label || 'TODAS', 'colspan="2"']],
            [['Início', DateToString(options.startDate, 'date')], ['Fim', DateToString(options.endDate, 'date')]]
        ]
    },
    print: async (options: any, values: ClientReportValue[]) => {
        return {
            name: 'Relatório de Clientes',
            options: {},
            summaries: await ClientReport.summaries(options, values),
            columns: [
                ...ClientReport.columns as any,
            ],
            values,
        }
    }
}

export default ClientReport;