import { Work } from "@mui/icons-material";
import { Routes } from "react-router-dom";
import MakeCRUDRoutes from "../../shared/crud/make_crud_routes";
import PhoneSOCreate from "./create";
import PhoneSOList from "./list";
import PhoneSOEdit from "./edit";
import { SendTech } from "./send_tech";
import Checkout from "./checkout";
import { CancelSO } from "./cancel_so";
import FinishSo from "./finish_so";

export default function PhoneSOModule(){
    const crudRoutes = MakeCRUDRoutes({
        perm: 'phone_so',
        routes: {
            create: {Component: PhoneSOCreate, path: '/create/*'},
            update: {Component: PhoneSOEdit, expectedPerm: ['s']},
            view: {Component: PhoneSOEdit, expectedPerm: ['w', 'r'], ComponentProps: {readOnly: true}},
            list: {Component: PhoneSOList},
            send: {Component: SendTech, path: '/send/:mode'},
            checkout: {Component: Checkout, path: '/checkout/*'},
            cancel: {Component: CancelSO, path: '/cancel'},
            finish: {Component: FinishSo, path: '/finish/*'},
        }
    });

    return (
        <Routes>
            {crudRoutes}
        </Routes>
    )
}

export const routeConfig: RouteConfig = {
    key: 'phone_so',
    label: 'OS',
    Icon: Work,
}