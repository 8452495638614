import { Stack, Paper, Typography } from "@mui/material";
import { useContext, useState } from 'react';
import { EasyFormInput } from "../../../shared/components/easy_form";
import FullFlexEasyForm from "../../../shared/components/full_flex_easy_form";
import NumberInput from "../../../shared/components/number_input";
import useIncrementState from "../../../shared/hooks/use_increment_state";
import PaymentInputs, { PaymentInputsChangeProps } from "../../payment/payment_inputs";
import SellBottomNavigation, { SellBottomButtonPrevirous, SellBottomButtonNextForm, SellBottomButtonNext } from "../sell_bottom_navigation";
import SellWizardContext from "../sell_wizard_context";

export default function Payment(props: {name: string}){

    const {sell} = useContext(SellWizardContext);
    const [paymentState, setPaymentState] = useState<PaymentInputsChangeProps>({} as any);


    const submit = async () => {
        if(!paymentState.valid){
            throw new Error('Preencha os valores de pagamento para serem de acordo com o total pago.')
        }
        sell.payment = paymentState.values;
    }


    return (
        <Stack flex={1}>
            <Stack flex={1} marginTop={1} padding={1}>
                <Paper>
                    <Stack spacing={1} padding={1}>
                        <Typography align="center">
                            PAGAMENTO
                        </Typography>
                        <PaymentInputs
                            totalValue={sell.totalValue}
                            defaults={sell.payment}
                            onChangeInputs={v => setPaymentState(v)}
                        />
                    </Stack>
                </Paper>
            </Stack>
            <SellBottomNavigation>
                <SellBottomButtonPrevirous/>
                <SellBottomButtonNext name={props.name} beforeRun={submit}/>
            </SellBottomNavigation>
        </Stack>
    )
}