import { useEffect, useMemo, useState } from "react";
import List from "../../shared/components/list";
import { hashDate, useSummariesController } from "./controller";
import usePromisedState from "../../shared/hooks/use_promised_state";
import PhoneSOService from "../phone_so/service";
import GoField, { goFieldColumn } from "../../shared/crud/use_crud_list/go_field";
import { getChange } from "../payment/model";
import { calcTax } from "../general_configs/model";

export default function SummariesPhoneSOList(){
    const controller = useSummariesController();
    
    const service = useMemo(() => new PhoneSOService(), []);
    const getSells = () => service.list().then(s => Object.values(s));
    const [list] = usePromisedState({initialState: getSells})
    const [filtred, setFiltred] = useState<typeof list['value']>([]);

    useEffect(() => {
        if(list.resolved){
            setFiltred(list.value?.filter(i => i.data.cancelled ? i.data.cancelAfterPay && controller.filterItem({date: i.data.dateCancel, storeUid: i.data.store?.uid!}) : controller.filterItem({date: i.data.dateOutcome, storeUid: i.data.store?.uid!})));
        }
    }, controller.listsDeps(list.resolved));
    
    return (
        <List
            rows={filtred!}
            defaultSort={[{ field: 'updAt', sort: 'desc' }]}
            getRowId={(r) => r.uid}
            columns={[
                //@ts-ignore
                {...goFieldColumn, renderCell: p => <GoField {...p} prefix="/phone_so" target="_blank"/>},
                {field: 'uid', headerName: 'ID', },
                {field: 'data.status', headerName: 'Status', },
                {field: 'data.seller.label', headerName: 'Vendedor', },
                {field: 'data.store.label', headerName: 'Loja', },
                {field: 'data.assignedTech.label', headerName: 'Técnico', },
                {field: 'data.suggestedValue', headerName: 'Valor Inicial', type: "money" },
                {field: 'data.totalValue', headerName: 'Faturamento', type: "money" },
                {field: 'tax', headerName: 'Taxas', type: "money", valueGetter: p => calcTax(p.row.data)},
                {field: 'data.costValue', headerName: 'Custo', type: "money", valueGetter: p => p.row.data?.costValue + calcTax(p.row.data)},
                {field: 'profit', headerName: 'Lucro', type: "money", valueGetter: p => p.row.data?.totalValue - (p.row.data?.costValue + calcTax(p.row.data)) },
                {field: 'data.payment.money', headerName: 'Dinheiro', type: "money" },
                {field: 'data.payment.pix', headerName: 'PIX', type: "money" },
                {field: 'data.payment.debt', headerName: 'Débito', type: "money" },
                {field: 'data.payment.credit', headerName: 'Crédito', type: "money" },
                {field: 'change', headerName: 'Troco', type: "money", valueGetter: p => getChange(p.row.data.payment, p.row.data.totalValue) },
                {field: 'data.dateOutcome', headerName: 'Pago em', type: 'dateTime'},
            ]}
            autoHeight
            loading={!list.resolved}
        />
    )
}