import BodyParser from "./body_parser";
import { getToken, updateToken } from "./token_manager";
import general from "../../../.env/general.json";
import version from "../../../configs/version.json";
import projectId from "../project_id";


let PROJECT = projectId;
const ROOT_URL = general.REACT_APP_API_URL!;

export type FetcherMethods = 'GET'|'POST'|'PATCH'|'PUT'|'DELETE';

export class FetchError extends Error{
    statusCode?: number;
    response?: Response;
    constructor(message?: string, statusCode?: number, response?: Response, options?: ErrorOptions){
        super(message, options);
        this.statusCode = statusCode;
        this.response = response;
    }
}

export default async function fetcher(url: string, method: FetcherMethods = 'GET', body?: any, init?: RequestInit){
    
    let addOptions: any = {};

    if(!(body instanceof FormData)){
        addOptions["Content-Type"] = typeof body === "object" ? 'application/json' : '';
        body = BodyParser(body);
    }

    const token = getToken();
    
    return fetch(`${ROOT_URL}/${url}`, {
        body,
        method,
        ...init,
        credentials: "include",
        headers: {
            project_name: PROJECT,
            client_version: version.n,
            ...addOptions,
            ...init?.headers,
            'x-auth-token': token||'',
        }
    })
    .then(async resp => {
        updateToken(resp);
        if(resp.status >= 400){
            const result = await resp.text();
            console.log(resp.status, result);
            let message: string;
            try{
                const m = JSON.parse(result);
                message = m.message || errorList[resp.status] || 'Erro desconhecido.';
            }
            catch(err){
                message = errorList[resp.status] || 'Erro desconhecido.';
            }
            throw new FetchError(message, resp.status, resp);
        }
        return resp;
    });
}

const errorList: {[key: string]: string} = {
    404: 'Item não localizado.',
    401: 'Usuário não conectado.',
    403: 'Acesso negado.'
}