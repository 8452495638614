import BetterDate from "../../../shared/utils/better_date";
import DateToString from "../../../shared/utils/date_to_string";
import MinPerm from "../../../shared/utils/min_perm";
import parseBRL from "../../../shared/utils/parse_brl";
import { calcTax } from "../../general_configs/model";
import PhoneSOService from "../../phone_so/service";
import { ProductProps } from "../../product/model";
import SellService from "../../sell/service";
import StoreDBRef from "../../store/dbref";
import { Report } from "../model";

export interface StoreReportValue{
    id: string;
    cod: string;
    product: string;
    sugestion: number;
    invoice: number;
    cost: number;
    profit: number;
    date: EpochTimeStamp;
    isSO: boolean;
    isTax?: boolean;
}

const StoreReport: Report = {
    name: 'Produtividade Lojas',
    checkPerm: async u => MinPerm(u.perms.report, 'r'),
    options: {
        startDate: {
            field: 'startDate',
            type: 'date',
            required: true,
            label: 'Data Início',
        },
        endDate: {
            field: 'endDate',
            type: 'date',
            required: true,
            label: 'Data Fim',
        },
        store: {
            field: 'store',
            type: 'dbref',
            component: StoreDBRef,
            label: 'Loja',
            required: true,
        }
    },
    validateOptions: async o => {
        if(o.startDate > o.endDate) throw new Error('A data de início não pode ser maior que a data fim.');
        o.startDate.setHours(0,0,0);
        o.endDate.setHours(23,59,0);
        return o;
    },
    columns: [
        {field: 'cod', headerName: 'Id'},
        {field: 'product', headerName: 'Produto'},
        {field: 'sugestion', headerName: 'Sugestão', type: 'money'},
        {field: 'invoice', headerName: 'Faturamento', type: 'money'},
        {field: 'cost', headerName: 'Custo', type: 'money'},
        {field: 'profit', headerName: 'Lucro', type: 'money'},
        {field: 'date', headerName: 'Data', type: 'dateTime'},
    ],
    defaultSort: [{field: 'date', sort: 'desc'}],
    process: async o => {
        const sells = await new SellService().list();
        const sos = await new PhoneSOService().list();
        const values: StoreReportValue[] = [];
        for(let s of Object.values(sells)){
            if(
                !s.data.cancelled && 
                s.data.crtAt >= o.startDate && 
                s.data.crtAt <= o.endDate &&
                s.data.store?.uid === o.store.uid
            ){
                for(let [key, item] of Object.entries(s.data.items)){
                    const amount = item.amount || 0;
                    const cost = (item.costValue || 0) * amount;
                    const sugestion = (item.idealSellValue || 0) * amount;
                    const invoice = (item.sellValue || 0) * amount;
                    const profit = invoice - cost;
                    values.push({
                        id: `${s.uid}_${key}`,
                        cod: s.uid!,
                        date: s.data.crtAt,
                        sugestion,
                        cost,
                        invoice,
                        profit,
                        product: item.product.label,
                        isSO: false,
                    })
                }
                const tax = calcTax(s.data);
                values.push({
                    id: `${s.uid}_tax`,
                    cod: s.uid!,
                    date: s.data.crtAt,
                    sugestion: 0,
                    cost: tax,
                    invoice: 0,
                    profit: -1* tax,
                    product: 'TAXAS',
                    isSO: false,
                    isTax: true,
                })
            }
        }
        for(let s of Object.values(sos)){
            const sDate: any = s.data.dateOutcome || s.data.dateIncome || s.data.crtAt;
            if(
                !s.data.cancelled &&
                s.data.payment &&
                sDate >= o.startDate && 
                sDate <= o.endDate &&
                s.data.store?.uid === o.store.uid
            ){
                values.push({
                    id: s.uid!,
                    cod: s.uid!,
                    date: sDate,
                    cost: s.data.costValue || 0,
                    invoice: s.data.totalValue || 0,
                    sugestion: s.data.suggestedValue || 0,
                    profit: (s.data.totalValue || 0) - (s.data.costValue || 0),
                    product: 'O.S',
                    isSO: true,
                })
                const tax = calcTax(s.data);
                values.push({
                    id: `${s.uid}_tax`,
                    cod: s.uid!,
                    date: s.data.crtAt,
                    sugestion: 0,
                    cost: tax,
                    invoice: 0,
                    profit: -1* tax,
                    product: 'TAXAS',
                    isSO: false,
                    isTax: true,
                })
            }
        }
        return values;
    },
    summaries: async (options: any, values: StoreReportValue[]) => {
        let so = 0, sells = 0, totalValue = 0, profitValue = 0;
        values.forEach(p => {
            if(!p.isTax){
                if(p.isSO) so++;
                else sells++;
            }
            totalValue += p.invoice;
            profitValue += p.profit;
        })
        return [
            [['Loja', options.store.label, 'colspan="2"']],
            [['Início', DateToString(options.startDate, 'date')], ['Fim', DateToString(options.endDate, 'date')]],
            [['O.S. Realizadas', String(so)], ['Vendas Realizadas', String(sells)]],
            [['Valor Faturado', parseBRL(totalValue)], ['Valor Lucrado', parseBRL(profitValue)]]
        ]
    },
    print: async (options: any, values: StoreReportValue[]) => {
        return {
            name: 'Relatório de Produtividade Lojas',
            options: {},
            summaries: await StoreReport.summaries(options, values),
            columns: [
                ...StoreReport.columns as any,
            ],
            values,
        }
    }
}

export default StoreReport;