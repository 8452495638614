import { forwardRef, useState } from "react";
import FixedTextField, { FixedTextFieldProps } from "../fixed_text_field";

const forbidden = ['+', '-', '.', ',', 'e', 'E', ''];

type NumberValue = number | null | undefined;

export interface NumberInputProps extends Omit<FixedTextFieldProps, 'onChange'>{
    modeInt?: boolean;
    value?: NumberValue;
    defaultValue?: NumberValue;
    onChange?: (event: { target: {value?: number, name?: string} }) => void;
}

const NumberInput = forwardRef((props: NumberInputProps, ref: any) => {
    const divide = props.modeInt ? 1 : 100;
    const [value, setValue] = useState<NumberValue>(props.value|| props.defaultValue || null);
    const realValue = props.value !== undefined ? props.value : value;
    
    const treatNumber = (n: NumberValue | string): any => {
        if(n != null){
            const noDot = parseInt(String(n).split(".").join(""));
            if(!isNaN(noDot)){
                return (noDot / divide);
            }
        }
        return null;
    }

    const parseValue = (n: any) => {
        if(n != null && !props.modeInt){
            if(!n.toFixed) n = parseFloat(n);
            return n.toFixed(2);
        }
        return n;
    }

    return (
        <FixedTextField
            {...props}
            ref={ref}
            value={parseValue(realValue!)}
            type="number"
            onWheel={(e: any) => e.nativeEvent.target.blur()}
            onKeyDown={e => forbidden.includes(e.key) && e.preventDefault()}
            onChange={(e: any) => {
                const treated = treatNumber(e.target.value);
                setValue(treated);
                props.onChange?.({
                    target: {
                        name: props.name!,
                        value: treated
                    }
                });
            }}
        />
    )
});

export default NumberInput;