import { ButtonProps, CircularProgress } from "@mui/material";

type ButtonColors = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
type ButtonVariants = 'text' | 'outlined' | 'contained';

export interface DialogOptions{
    title?: any;
    content?: any;
    buttons?: any;
    locked?: boolean;
}

export interface DialogOkButtonOptions{
    content?: any;
    callback?: () => void;
    buttonProps?: ButtonProps;
    color?: ButtonColors;
    variant?: ButtonVariants;
}

export interface DialogFunctions{
    showDialog: (options: DialogOptions) => void;
    closeDialog: () => void;
    okButton: (options?: DialogOkButtonOptions) => void;
}

class EasyDialog{
    dialog?: DialogFunctions;

    setDialog = (dialog: DialogFunctions) => {
        this.dialog = dialog;
    }

    unsetDialog = () => {
        this.dialog = undefined;
    }
    
    showDialog = (options: DialogOptions) => {
        this.dialog?.showDialog(options);
    }

    closeDialog = () => {
        this.dialog?.closeDialog();
    }

    okButton = (options?: DialogOkButtonOptions) => {
        return this.dialog?.okButton(options);
    }

    showError = (error: Error | { message: string }, okCallback?: () => void) => {
        console.error("ERROR: ", error);
        if(!error) return;
        //@ts-ignore
        const isVersionError = error.statusCode == 495;
        this.showDialog({
            content: `${error.message}`,
            locked: isVersionError,
            buttons: isVersionError ? undefined : this.okButton({callback: () => {
                this.closeDialog();
                okCallback?.();
            }}),
        });
    }

    showLoading = (message?: string) => {
        this.showDialog({
            title: message || 'Carregando...',
            content: <div style={{ alignSelf: 'center' }}><CircularProgress color="primary"/></div>,
            locked: true,
        });
    }

    showConfirmation = (options: DialogOptions & {callback: () => void, color?: ButtonColors}) => {
        const { callback, color, ...other} = options;
        dialogHelper.showDialog({
            title: 'Tem certeza disso?',
            content: 'Esta ação não pode ser desfeita!',
            buttons: [
                dialogHelper.okButton({content: 'NÃO', color: 'inherit', variant: 'text'}),
                dialogHelper.okButton({content: 'SIM', callback, color}),
            ],
            ...other,
        })
    }
}


const dialogHelper = new EasyDialog();

export default dialogHelper;