import { AppBar, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, Toolbar, Typography } from "@mui/material";
import useCRUDEdit, { UseCRUDEditComponentProps } from "../../shared/crud/use_crud_edit";
import { CashRemittanceFields, CashRemittanceProps } from "./model";
import CashRemittanceService from "./service";
import { Close } from "@mui/icons-material";
import { EasyForm, EasyFormInput, EasyFormWriteOnly, EasyFormAction } from "../../shared/components/easy_form";
import { DBRef } from "../../shared/models/model";
import { StoreProps } from "../store/model";
import If from "../../shared/components/if";

export interface CashRemittanceMicroEditProps extends UseCRUDEditComponentProps<CashRemittanceProps>{
    store: DBRef<StoreProps, 'name'>;
    mode: 'create' | 'request';
    readOnlyMode?: boolean;
    onlyMoney?: boolean;
}

export default function CashRemittanceMicroEdit(props: CashRemittanceMicroEditProps){
    const create = useCRUDEdit({
        props: props,
        fieldsGen: (opts) => CashRemittanceFields({...opts, _createMode: props.mode}),
        CRUDService: CashRemittanceService,
        beforeSave: (_, data) => {
            data._createMode = props.mode;
            if(props.mode === 'create') data.manual = true;
            else{
                //@ts-ignore
                data.reference = data._originStore;
                //@ts-ignore
                delete data._originStore;
            }
            data.store = props.store;
        },
    });

    if(create.initializing){
        return (
            <Dialog open>
                <DialogContent>
                    <CircularProgress color="primary"/>
                </DialogContent>
            </Dialog>
        )
    }
    return (
        <Dialog open onClose={create.gotoIndex}>
            <DialogTitle>
                {props.mode === 'create' ? 'Movimentação de caixa' : 'Envio de numerário'}
            </DialogTitle>
            <EasyForm {...create.easyFormProps}>
                <DialogContent>
                    <Stack spacing={1}>
                        <EasyFormInput field="_operationMode" defaultValue={props?.mode === 'create' ? 'sub' : 'add'} readOnly={props.readOnlyMode}/>
                        <EasyFormInput field="_originStore"/>
                        <EasyFormInput field="lastOpReason"/>
                        <EasyFormInput field="lastOpObs"/>
                        <Paper >
                            <Stack spacing={0.5} padding={1}>
                                <Typography>
                                    Valores
                                </Typography>
                                <EasyFormInput field="values.money"/>
                                <If c={!props.onlyMoney!}>
                                    <EasyFormInput field="values.pix"/>
                                    <EasyFormInput field="values.credit"/>
                                    <EasyFormInput field="values.debt"/>
                                </If>
                            </Stack>
                        </Paper>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="inherit"
                        onClick={create.gotoIndex}
                        aria-label="close"
                        variant='text'
                    >
                        CANCELAR
                    </Button>
                    <EasyFormWriteOnly>
                        <EasyFormAction>
                            <Button color="success" type="submit">
                                SALVAR
                            </Button>
                        </EasyFormAction>
                    </EasyFormWriteOnly>
                </DialogActions>
            </EasyForm>
        </Dialog>
    )
}