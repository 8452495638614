import { ShoppingBasket } from "@mui/icons-material";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import SelectItems from "./select_items";
import SellWizardContext, { SellWizardContextValue } from "./sell_wizard_context";
import { useState, useEffect } from "react";
import ItemDetails from "./item_details";
import SellDetails from "./sell_details";
import Payment from "./payment";
import Summary from "./summary";
import useAuth from "../../shared/hooks/use_auth";
import Success from "./success";

export const routeConfig: RouteConfig = {
    key: 'sell_wizard',
    label: 'Vender',
    Icon: ShoppingBasket,
    buttonProps: {
        color: 'success'
    }
}

export const SellWizardOrder = [
    {name: '', Component: SelectItems},
    {name: 'item_details', Component: ItemDetails },
    {name: 'sell_details', Component: SellDetails },
    {name: 'payment', Component: Payment },
    {name: 'summary', Component: Summary },
    {name: 'success', Component: Success},
]

export default function SellWizardModule(){
    const navigate = useNavigate();
    const auth = useAuth();
    const [sellWizardContextValue] = useState<SellWizardContextValue>(() => ({
        sell: {
            items: {}, 
            payment: {}, 
            zone: {...auth.user?.user.configs.zone},
            store: {...auth.user?.user.configs.store},
            seller: {uid: auth.user?.user.uid, label: auth.user?.user.name},
        } as any,
    }));
    
    useEffect(() => {
        navigate('.');
    },[]);

    return (
        <SellWizardContext.Provider value={sellWizardContextValue}>
            <Routes>
                {SellWizardOrder.map((item) => <Route element={<item.Component name={item.name}/>} path={item.name}/>)}
            </Routes>
        </SellWizardContext.Provider>
    )
}