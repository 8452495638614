import { useState, useContext } from 'react';
import { CRUDItem } from '../../../shared/crud/crud_service';
import usePromisedState from '../../../shared/hooks/use_promised_state';
import { ProductProps } from '../../product/model';
import ProductService from '../../product/service';
import SellWizardContext from '../sell_wizard_context';

export default function SelectItemsController(){

    const [productService] = useState(() => new ProductService());
    const [products] = usePromisedState({initialState: () => productService.list().then(v => Object.values(v))});
    const context = useContext(SellWizardContext);
    const [selectedProducts, setSelectedProducts] = useState<any[]>(() => Object.keys(context.sell.items));
    

    const submit = async () => {
        if(!selectedProducts.length) throw new Error("Nenhum item selecionado!");
        for(let productUid of selectedProducts){
            const product = products.value?.find(p => p.uid === productUid)!;
            const existing = context.sell.items[productUid];
            if(!existing){
                context.sell.items[productUid] = {
                    product: {uid: productUid, label: product.data.name},
                    amount: 1,
                    costValue: product.data.cost_value,
                    idealSellValue: product.data.sell_value,
                    fonts: {},
                    gift: false
                } as any;
            }
        }
        for(let key in context.sell.items){
            if(!selectedProducts.includes(key)){
                delete context.sell.items[key];
            }
        }
    }

    return {
        products,
        selectedProducts,
        setSelectedProducts,
        submit,
    }
}